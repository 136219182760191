export const defaultState = () => ({
  currentIndex: 0,
  currentLesson: null,
  currentSectionIndex: 0,
  currentSectionId: 0,
  completedLessons: [],
  categorizedSections: [],
  currentCategorizedLessonLocation: {
    categoryIndex: 0,
    sectionIndex: 0,
    lessonIndex: 0,
  },
});

export default () => defaultState();
