import types from './types';
import CourseEntity from '~/core/entities/course/course.entitiy';

export default {
  async addUserToWaittingList({ getters, dispatch }) {
    try {
      const utmQueryObject = this.$services.utm.getUtmQueryObject();
      await this.$api.course.addUserToWaittingList(getters.course.id, utmQueryObject);
      dispatch('student/addUserToWaittingList', true, { root: true });
      this.$toast.success('You have been added to the waiting list.');
    } catch (error) {}
  },

  async removeUserToWaittingList({ getters, dispatch }, params) {
    try {
      const utmQueryObject = this.$services.utm.getUtmQueryObject();
      await this.$api.course.removeUserToWaittingList(getters.course.id, params, utmQueryObject);
      dispatch('student/addUserToWaittingList', false, { root: true });
      this.$toast.info('You have been removed from the waiting list.');
    } catch (error) {}
  },

  async fetchCourses({ commit }) {
    const { data } = await this.$api.course.fetchCourses();
    data.forEach((fieldCourses) => {
      fieldCourses.courses = fieldCourses.courses.map((course) => new CourseEntity(course));
    });
    commit(types.SET_COURSES, data);
  },

  async fetchServices({ commit }) {
    const { data } = await this.$api.course.fetchServices();
    commit(types.SET_COURSES, data);
  },

  async fetchCourseClasses({ commit }, slug) {
    const { data } = await this.$api.course.fetchCourseClasses(slug);
    commit(types.SET_CLASSES, data);
  },

  async fetchCourseTiers({ commit }, course_id) {
    commit(types.RESET_TIERS);
    const { data } = await this.$api.course.fetchCourseTiers(course_id);
    commit(types.SET_TIERS, data);
  },

  async fetchTestimonials() {
    const { data } = await this.$api.course.fetchTestimonials();
    return data;
  },

  async fetchCourseViaContent({ commit }, slug) {
    commit(types.SET_COMPONENT_LOADING_COURSE_LEARNING_PRIVATE, true);
    const { data } = await this.$api.course.fetchCourseViaContent(slug);
    commit(types.SET_COURSE, data);
    commit(types.SET_COMPONENT_LOADING_COURSE_LEARNING_PRIVATE, false);
  },

  async fetchCourseForPreview({ commit }, slug) {
    const { data } = await this.$api.course.fetchCourseForPreview(slug);
    commit(types.SET_COURSE, data);
  },

  async fetchPublishedCourseForPublic({ commit }, slug) {
    commit(types.SET_SENDY_STATUS, false);
    const { data } = await this.$api.course.fetchPublishedCourseForPublic(slug);
    commit(types.SET_COURSE, data);
    return data;
  },

  async fetchCourseViaContentForPreview({ commit }, slug) {
    commit(types.SET_COMPONENT_LOADING_COURSE_LEARNING_PRIVATE, true);
    const { data } = await this.$api.course.fetchCourseViaContentForPreview(slug);
    commit(types.SET_COURSE, data);
    commit(types.SET_COMPONENT_LOADING_COURSE_LEARNING_PRIVATE, false);
  },

  async fetchCourseLessons({ commit }, params) {
    try {
      const { data } = await this.$api.course.fetchCourseLessons({
        ...params,
      });
      if (params?.weekly_non_completed_lessons === 1) {
        commit(types.SET_WEEK_MANDATORY_LESSONS, data);
      } else {
        commit(types.SET_COURSE_LESSONS, data);
      }
    } catch (error) {}
  },

  async fetchPublishedCourseForPublicNoReset({ commit }, slug) {
    const { data } = await this.$api.course.fetchPublishedCourseForPublic(slug);
    commit(types.SET_COURSE, data);
  },

  async fetchCourseTopics({ commit }, access) {
    const { data } = await this.$api.course.fetchCourseTopics(access);
    commit(types.SET_TOPICS, data);
  },

  async setStudyPlan({ dispatch, getters }, params) {
    await this.$api.course.setStudyPlan(params);
    this.$toast.success('Your Study Schedule has been generated.');
    await dispatch('fetchPublishedCourseForPublicNoReset', getters.course.slug);
  },

  async disableStudyPlan({ dispatch, getters }, enrollment_id) {
    await this.$api.course.disableStudyPlan(enrollment_id);
    this.$toast.info('Your Study Schedule has been disabled.');
    await dispatch('fetchCourseViaContent', getters.course.slug);
  },

  async setStudyContinue({ dispatch }, params) {
    await this.$api.course.setStudyContinue(params);
    await dispatch('fetchCourseLessons', {
      course_id: params.course_id,
      study_level: 'mandatory',
      weekly_non_completed_lessons: 1,
    });
  },

  async subscribeToSendy({ commit }, params) {
    commit(types.SET_COMPONENT_LOADING_SENDY, true);
    const utmQueryObject = this.$services.utm.getUtmQueryObject();
    await this.$api.course.subscribeToSendy(params, utmQueryObject);
    commit(types.SET_SENDY_STATUS, true);
    commit(types.SET_COMPONENT_LOADING_SENDY, false);
  },

  async getSendyStatus({ commit }, params) {
    commit(types.SET_COMPONENT_LOADING_SENDY, true);
    const { data } = await this.$api.course.getSendyStatus(params);
    commit(types.SET_SENDY_STATUS, data.status);
    commit(types.SET_COMPONENT_LOADING_SENDY, false);
  },

  setCourse({ commit }, course) {
    commit(types.SET_COURSE, course);
  },

  onToggleTrialSessionDialog({ commit }, state) {
    commit(types.SET_SHOW_TRIAL_SESSION_DIALOG, state);
  },

  toggleLessonComplete({ commit }, lessonId) {
    commit(types.TOGGLE_LESSON_COMPLETED, lessonId);
  },

  toggleWeeklyLessonComplete({ commit }, lessonId) {
    commit(types.TOGGLE_WEEKLY_LESSON_COMPLETED, lessonId);
  },

  async fetchDailyBySlug(_, { slug, href }) {
    const { data } = await this.$api.course.fetchDailyBySlug(slug);
    if (href) {
      data.href = href;
    }
    return data;
  },

  async fetchTestAnalysis2(_, dailyId) {
    const { data } = await this.$api.qBank.fetchTestAnalysis2(dailyId);
    return data;
  },

  async subscribeMarketingSendy({ commit }, params) {
    const utmQueryObject = this.$services.utm.getUtmQueryObject();
    await this.$api.course.subscribeMarketingSendy(params, utmQueryObject);
    commit(types.SET_SENDY_STATUS, true);
  },

  async fetchFields() {
    const { data } = await this.$api.course.fetchFields();
    return data;
  },

  resetCourseState({ commit }) {
    commit(types.RESET_STATE);
  },

  async setLessonHistory({ commit, getters }, { lessonId, params }) {
    const hasEnrollment = !!getters.course?.enrollment;
    if (hasEnrollment) {
      await this.$api.course.setLessonHistory(lessonId, params);
      commit(types.SET_LATEST_VISITED_LESSON, lessonId);
      if (params.watched_time !== null && params.watched_time >= 0) {
        commit(types.MODIFY_LESSON_PROPERTY, { lessonId, key: 'watched_time', value: params.watched_time });
      }
    }
  },
};
