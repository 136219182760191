export default {
  INCREASE_INDEX: 'INCREASE_INDEX',
  DECREASE_INDEX: 'DECREASE_INDEX',
  SET_CURRENT_SECTION_ID: 'SET_CURRENT_SECTION_ID',
  SET_CURRENT_LESSON: 'SET_CURRENT_LESSON',
  SET_CURRENT_INDEX: 'SET_CURRENT_INDEX',
  SET_COMPLETED_LESSONS: 'SET_COMPLETED_LESSONS',
  PUSH_TO_COMPLETED_LESSONS: 'PUSH_TO_COMPLETED_LESSONS',
  REMOVE_FROM_COMPLETED_LESSONS: 'REMOVE_FROM_COMPLETED_LESSONS',
  SET_CATEGORIZED_SECTIONS: 'SET_CATEGORIZED_SECTIONS',
  SET_CURRENT_CATEGORIZED_LESSON_LOCATION: 'SET_CURRENT_CATEGORIZED_LESSON_LOCATION',
  COLLAPSE_CATEGORIES: 'COLLAPSE_CATEGORIES',
  TOGGLE_CATEGORY: 'TOGGLE_CATEGORY',
  COLLAPSE_SECTIONS: 'COLLAPSE_SECTIONS',
  TOGGLE_SECTION: 'TOGGLE_SECTION',
  RESET_STATE: 'RESET_STATE',
};
