import Vue from 'vue';
import moment from 'moment';
import { numberToWord } from '~/core/utils/numbers';

Vue.filter('textLimit', (value, size) => {
  if (!value) {
    return '';
  }
  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});

Vue.filter('intlNumber', (value, locales = 'en', options = { notation: 'compact' }) => {
  if (isNaN(value)) {
    return value;
  }

  try {
    return new Intl.NumberFormat(locales, options).format(Number(value));
  } catch {
    return value;
  }
});

Vue.filter('secondsToTime', (value) => {
  if (isNaN(value)) {
    return null;
  }

  try {
    const days = Math.floor(value / 86400);
    const time = moment.utc((value % 86400) * 1000).format('HH:mm:ss');

    if (days) {
      return `${days}${days > 1 ? 'Days' : 'Day'} ${time}`;
    }

    return time;
  } catch {
    return null;
  }
});

/**
 * convert seconds to minutes, example: 120 ==> '2-minutes'
 * @returns minutes in numbers with minutes-postfix
 * @param {number} value
 */
Vue.filter('secondsToMinutes', (value, withDash = true, pluralize = true) => {
  if (isNaN(value)) {
    return null;
  }
  try {
    const minutes = Math.floor(value / 60);
    return `${minutes}${withDash ? '-' : ' '}${pluralize ? Vue.options.filters.pluralize(minutes, 'minute') : 'minute'}`;
  } catch {
    return null;
  }
});

// Input: 2500
// Output: 41 Mins 40 Secs
Vue.filter('secondsToCharTime', (value) => {
  if (isNaN(value)) {
    return null;
  }

  try {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    if (hours) {
      return `${hours} ${hours > 1 ? 'Hours' : 'Hour'} ${minutes} ${minutes > 1 ? 'Mins' : 'Min'} ${seconds} ${
        seconds > 1 ? 'Secs' : 'Sec'
      }`;
    } else if (minutes) {
      return `${minutes} ${minutes > 1 ? 'Mins' : 'Min'} ${seconds} ${seconds > 1 ? 'Secs' : 'Sec'}`;
    } else {
      return `${seconds} ${seconds > 1 ? 'Secs' : 'Sec'}`;
    }
  } catch {
    return null;
  }
});

Vue.filter('fullDate', (value) => {
  try {
    const date = moment(value);

    if (date.isValid()) {
      return date.format('MM/DD/YYYY');
    } else {
      return null;
    }
  } catch {
    return null;
  }
});

Vue.filter('fullDateTime', (value) => {
  try {
    const date = moment(value);

    if (date.isValid()) {
      return date.format('MM/DD/YYYY HH:mm');
    } else {
      return null;
    }
  } catch {
    return null;
  }
});

Vue.filter('dateFormat', (value, format = 'MM/DD/YYYY') => {
  try {
    const date = moment(value);

    if (date.isValid()) {
      return date.format(format);
    } else {
      return null;
    }
  } catch {
    return null;
  }
});

Vue.filter('numberToWord', (number) => {
  return numberToWord(number);
});

Vue.filter('removeHtmlTags', (value) => {
  return value.toString().replace(/(<([^>]+)>)/gi, '');
});
