import { defaultState } from './state';
import types from './types';

const getCompletedLessons = (course) => {
  const sections = course.sections;
  const lessons = [];
  if (Array.isArray(sections)) {
    sections.forEach((section) => {
      if ('lessons' in section) {
        section.lessons.forEach((lesson) => {
          if (lesson.completed) {
            lessons.push(lesson.id);
          }
        });
      }
    });
  }
  return lessons;
};

export default {
  [types.INCREASE_INDEX](state) {
    state.currentIndex++;
  },

  [types.DECREASE_INDEX](state) {
    state.currentIndex--;
  },

  [types.SET_CURRENT_SECTION_ID](state, sectionId) {
    state.currentSectionId = sectionId;
  },

  [types.SET_CURRENT_LESSON](state, lesson) {
    if (state.currentLesson && state.currentLesson.id === lesson.id) {
      return;
    }
    state.currentLesson = Object.assign({}, lesson);
  },

  [types.PUSH_TO_COMPLETED_LESSONS](state, lessonId) {
    if (!state.completedLessons.includes(lessonId)) {
      state.completedLessons.push(lessonId);
    }
  },

  [types.REMOVE_FROM_COMPLETED_LESSONS](state, lessonId) {
    const index = state.completedLessons.indexOf(lessonId);
    if (index > -1) {
      state.completedLessons.splice(index, 1);
    }
  },

  [types.SET_COMPLETED_LESSONS](state, course) {
    state.completedLessons = getCompletedLessons(course);
  },

  [types.SET_CURRENT_INDEX](state, index) {
    state.currentIndex = index;
  },

  [types.SET_CATEGORIZED_SECTIONS](state, payload) {
    state.categorizedSections = [...payload];
  },

  [types.SET_CURRENT_CATEGORIZED_LESSON_LOCATION](state, location) {
    state.currentCategorizedLessonLocation = location;
  },

  [types.TOGGLE_CATEGORY](state, { getters, payload }) {
    const { categoryIndex } = payload;
    let { isExpanded } = payload;
    const currentIsExpanded = getters.categorizedSections[categoryIndex].isExpanded;
    if (isExpanded === currentIsExpanded) {
      return;
    }
    if (isExpanded === undefined) {
      isExpanded = !currentIsExpanded;
    }
    state.categorizedSections[categoryIndex].isExpanded = isExpanded;
  },

  [types.COLLAPSE_CATEGORIES](state, { exceptCategoryIndex }) {
    state.categorizedSections.forEach((category, index) => {
      if (category.isExpanded && index !== exceptCategoryIndex) {
        category.isExpanded = false;
        category.sections.forEach((section) => (section.isExpanded = false));
      }
    });
  },

  [types.TOGGLE_SECTION](state, { getters, payload }) {
    const { categoryIndex, sectionIndex } = payload;
    let { isExpanded } = payload;
    const currentIsExpanded = getters.categorizedSections[categoryIndex].sections[sectionIndex].isExpanded;
    if (isExpanded === currentIsExpanded) {
      return;
    }
    if (isExpanded === undefined) {
      isExpanded = !currentIsExpanded;
    }
    state.categorizedSections[categoryIndex].sections[sectionIndex].isExpanded = isExpanded;
  },

  [types.COLLAPSE_SECTIONS](state, { categoryIndex, exceptSectionIndex }) {
    state.categorizedSections[categoryIndex].sections.forEach((section, index) => {
      if (section.isExpanded && index !== exceptSectionIndex) {
        section.isExpanded = false;
      }
    });
  },

  [types.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },
};
